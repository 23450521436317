import type {GraphQLError} from 'graphql/error';

import {
  EmailLoginMutation,
  EmailLoginMutationVariables,
  LoginPageDocument,
  useEmailLoginMutation,
} from './__generated__/LoginPage.graphql';
import {GlobalNavigationDocument} from '../../lib/components/layout/__generated__/GlobalNavigation.graphql';
import FormSubmissionError from '../../lib/errors/FormSubmissionError';
import translateOrFallback from '../../lib/i18n/translateOrFallback';
import ValidationError from '../../lib/validators/ValidationError';

import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

type Result = NonNullable<EmailLoginMutation['emailLogin']>;

export type DoMutation = (
  variables: EmailLoginMutationVariables,
) => Promise<Result>;

export default function useEmailLogin(): DoMutation {
  const {t} = useTranslation();
  const [commit] = useEmailLoginMutation({
    refetchQueries: [LoginPageDocument, GlobalNavigationDocument],
  });

  return useCallback(
    async (variables: EmailLoginMutationVariables) => {
      try {
        const result = await commit({
          variables,
        });
        const {authToken, refreshToken} = result.data?.emailLogin ?? {};

        if (typeof authToken === 'string' && typeof refreshToken === 'string') {
          return {authToken, refreshToken};
        }

        throw new ValidationError(
          t('email-credential.invalid-email-credential'),
        );
      } catch (err) {
        throw FormSubmissionError.fromGraphQLMutationError<Result>(
          err,
          (error: GraphQLError) => {
            return [
              {},
              [
                translateOrFallback(
                  error.message,
                  t('form.unknown-form-submission-error'),
                ),
              ],
            ];
          },
        );
      }
    },
    [commit],
  );
}
