import TextInput, {Props as TextInputProps} from './TextInput';
import {FormObject} from '../../../hooks/useForm';

import React from 'react';

type BaseRecord = Record<string, unknown>;

type OmitKeys = 'name' | 'value' | 'onChange';
export interface Props<T extends BaseRecord, TKey extends keyof T>
  extends Omit<TextInputProps, OmitKeys> {
  readonly formObject: FormObject<T>;
  readonly getValue: (fieldValues: T) => Maybe<string>;
  readonly label: string;
  readonly name: TKey;
}

export default function FormTextInput<
  T extends BaseRecord,
  TKey extends keyof T,
>(props: Props<T, TKey>): JSX.Element {
  const {formObject, getValue, label, name, ...others} = props;
  const {fieldErrors, fieldValues, setFieldValue} = formObject;

  return (
    <TextInput
      {...others}
      errorMessage={fieldErrors[name]}
      isInvalid={fieldErrors[name] != null}
      label={label}
      name={name as string}
      value={getValue(fieldValues) ?? undefined}
      onChange={(value) => setFieldValue(name, value as T[typeof name])}
    />
  );
}
