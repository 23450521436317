import {useLoginPageQuery} from './__generated__/LoginPage.graphql';
import useEmailLogin from './useEmailLogin';
import {ButtonColor} from '../../lib/components/design_system/Button/options';
import CheckboxGroup from '../../lib/components/design_system/form/CheckboxGroup';
import FormTextInput from '../../lib/components/design_system/form/FormTextInput';
import useAppTheme from '../../lib/components/design_system/hooks/useAppTheme';
import TextLink from '../../lib/components/design_system/Link/TextLink';
import {ContentColor} from '../../lib/components/design_system/theme/useContentPalette';
import Typography, {
  Size,
} from '../../lib/components/design_system/typography/Typography';
import FormSubmitButton from '../../lib/components/Form/FormSubmitButton';
import DefaultPageLayout from '../../lib/components/layout/DefaultPageLayout';
import Flex from '../../lib/components/layout/flex/Flex';
import FormPageContentWrapper from '../../lib/components/layout/FormPageContentWrapper';
import useForm from '../../lib/hooks/useForm';
import useNavigateToNextUrl from '../../lib/hooks/useNavigateToNextUrl';
import useRecordFieldValidator from '../../lib/hooks/useRecordFieldValidator';
import useFormValidators from '../../lib/hooks/useRecordValidator';
import {linkToApp} from '../../lib/url/linkToApp';
import allowNonEmptyString from '../../lib/validators/allowNonEmptyString';
import alwaysDeny from '../../lib/validators/alwaysDeny';
import NotFound from '../NotFound';

import {css} from '@emotion/react';
import React, {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

type LoginFormData = Readonly<{
  email: string;
  password: string;
  rememberMe: boolean;
}>;

export default function LoginPage(): JSX.Element {
  const {t} = useTranslation();
  const {data, loading: isLoading} = useLoginPageQuery();
  const {palettes, spacing} = useAppTheme();

  const navigate = useNavigateToNextUrl(linkToApp('/'));
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (!isLoading && data?.viewer != null) {
      navigate({
        replace: true,
      });
    }
  }, [data, isLoading, navigate]);

  const doLogin = useEmailLogin();
  const emailValidator = useRecordFieldValidator<LoginFormData, 'email'>(
    'email',
    allowNonEmptyString(
      alwaysDeny(
        t('validation.missing-required-field', {
          name: t('email-credential.email-address'),
        }),
      ),
    ),
  );
  const passwordValidator = useRecordFieldValidator<LoginFormData, 'password'>(
    'password',
    allowNonEmptyString(
      alwaysDeny(
        t('validation.missing-required-field', {
          name: t('email-credential.password'),
        }),
      ),
    ),
  );
  const onValidate = useFormValidators([emailValidator, passwordValidator]);

  const onSubmit = useCallback(
    async (inputs: LoginFormData) => {
      await doLogin({
        email: inputs.email,
        rawPassword: inputs.password,
        rememberMe: inputs.rememberMe,
      });
    },
    [doLogin],
  );

  const formObject = useForm<LoginFormData>({
    fallbackFormErrors: [t('form.unknown-form-submission-error')],
    onSubmit,
    onValidate,
    options: {
      forbidSubmissionWhenSuccess: true,
    },
    startingValue: {
      email: '',
      password: '',
      rememberMe: false,
    },
  });
  const {doSubmit, setFieldValue} = formObject;

  if (isLoading) {
    return <div>{t('common-strings.loading')}</div>;
  }

  if (!data?.featureFlags.login) {
    return <NotFound />;
  }

  return (
    <DefaultPageLayout
      showViewerNavControls={false}
      sx={css({
        backgroundColor: palettes.background.neutral.disabled,
        height: '100vh',
      })}
    >
      <FormPageContentWrapper
        sx={css({
          height: '85vh',
        })}
      >
        <Flex
          sx={css({
            flexDirection: 'column',
            gap: spacing.x16,
          })}
        >
          {/* Header */}
          <Typography
            size={Size.XL}
            variant="h1"
          >
            {t('pages.login.login-form-title')}
          </Typography>
          <Flex
            sx={css({
              whiteSpace: 'pre',
            })}
          >
            <Typography
              css={css({
                lineHeight: '1.4em',
              })}
              size={Size.S}
              variant="span"
            >
              {t('pages.login.new-user-instruction')}
            </Typography>
            <TextLink
              textProps={{
                color: ContentColor.SECONDARY,
              }}
              to={linkToApp('/signup', searchParams)}
            >
              {t('pages.login.signup-here-link-label')}
            </TextLink>
          </Flex>

          {/* Login form */}
          <div
            css={css({
              width: 320,
            })}
          >
            <form onSubmit={(e) => void doSubmit(e)}>
              <FormTextInput
                autoComplete="emailCredentialEmail"
                formObject={formObject}
                getValue={(fields) => fields.email}
                isRequired={true}
                label={t('email-credential.email-address')}
                name="email"
                type="email"
              />
              <FormTextInput
                autoComplete="emailCredentialPassword"
                formObject={formObject}
                getValue={(fields) => fields.password}
                isRequired={true}
                label={t('email-credential.password')}
                name="password"
                type="password"
              />
              <CheckboxGroup
                aria-label={t('pages.login.keep-me-logged-in')}
                name="rememberMe"
                options={[
                  {
                    ariaLabel: t('pages.login.keep-me-logged-in'),
                    id: 'rememberMe',
                    label: t('pages.login.keep-me-logged-in'),
                    value: 'rememberMe',
                  },
                ]}
                rootStyle={css({
                  marginTop: spacing.x20,
                })}
                onChange={(selected) =>
                  setFieldValue('rememberMe', selected.includes('rememberMe'))
                }
              />
              <FormSubmitButton
                color={ButtonColor.ACCENT}
                formObject={formObject}
                idleLabel={t('common-strings.login')}
                progressLabel={t('form.submitting')}
                sx={css({
                  marginTop: spacing.x40,
                  width: '100%',
                })}
              />
            </form>
          </div>

          {/* Forgot password */}
          <TextLink
            textProps={{
              color: ContentColor.SECONDARY,
            }}
            to={linkToApp('/reset-password')}
          >
            {t('pages.login.forgot-password-link-label')}
          </TextLink>
        </Flex>
      </FormPageContentWrapper>
    </DefaultPageLayout>
  );
}
