import type {Validator} from './validator';

import ValidationError from './ValidationError';

import {t} from 'i18next';

export default function alwaysDeny(
  customError: string | undefined = undefined,
): Validator<unknown> {
  return () => {
    throw new ValidationError(customError ?? t('validation.always-forbid'));
  };
}
