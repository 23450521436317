import * as Types from '../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoginPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LoginPageQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string } | null, featureFlags: { __typename?: 'FeatureFlags', login: boolean } };

export type EmailLoginMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  rawPassword: Types.Scalars['String']['input'];
  rememberMe: Types.Scalars['Boolean']['input'];
}>;


export type EmailLoginMutation = { __typename?: 'Mutation', emailLogin?: { __typename?: 'UserToken', authToken: string, refreshToken: string } | null };


export const LoginPageDocument = gql`
    query LoginPage {
  viewer {
    id
  }
  featureFlags {
    login
  }
}
    `;

/**
 * __useLoginPageQuery__
 *
 * To run a query within a React component, call `useLoginPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoginPageQuery(baseOptions?: Apollo.QueryHookOptions<LoginPageQuery, LoginPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginPageQuery, LoginPageQueryVariables>(LoginPageDocument, options);
      }
export function useLoginPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginPageQuery, LoginPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginPageQuery, LoginPageQueryVariables>(LoginPageDocument, options);
        }
export function useLoginPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LoginPageQuery, LoginPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoginPageQuery, LoginPageQueryVariables>(LoginPageDocument, options);
        }
export type LoginPageQueryHookResult = ReturnType<typeof useLoginPageQuery>;
export type LoginPageLazyQueryHookResult = ReturnType<typeof useLoginPageLazyQuery>;
export type LoginPageSuspenseQueryHookResult = ReturnType<typeof useLoginPageSuspenseQuery>;
export type LoginPageQueryResult = Apollo.QueryResult<LoginPageQuery, LoginPageQueryVariables>;
export const EmailLoginDocument = gql`
    mutation EmailLogin($email: String!, $rawPassword: String!, $rememberMe: Boolean!) {
  emailLogin(email: $email, rawPassword: $rawPassword, rememberMe: $rememberMe) {
    authToken
    refreshToken
  }
}
    `;
export type EmailLoginMutationFn = Apollo.MutationFunction<EmailLoginMutation, EmailLoginMutationVariables>;

/**
 * __useEmailLoginMutation__
 *
 * To run a mutation, you first call `useEmailLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailLoginMutation, { data, loading, error }] = useEmailLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      rawPassword: // value for 'rawPassword'
 *      rememberMe: // value for 'rememberMe'
 *   },
 * });
 */
export function useEmailLoginMutation(baseOptions?: Apollo.MutationHookOptions<EmailLoginMutation, EmailLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailLoginMutation, EmailLoginMutationVariables>(EmailLoginDocument, options);
      }
export type EmailLoginMutationHookResult = ReturnType<typeof useEmailLoginMutation>;
export type EmailLoginMutationResult = Apollo.MutationResult<EmailLoginMutation>;
export type EmailLoginMutationOptions = Apollo.BaseMutationOptions<EmailLoginMutation, EmailLoginMutationVariables>;